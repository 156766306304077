<template>
<div class="login">
  <!-- <h1 class="title grey--text pt-2">登录</h1> -->
  <v-container>
    <v-layout class="d-flex justify-end wrap">
      <v-flex xs7>
        <v-select
          v-model="roleCode"
          :items="roles"
          item-text="role"
          item-value="code"
          label="登录身份"
          single-line
          :rules="[required]"
          :disabled=true
        ></v-select>
      </v-flex>

      <v-flex xs7>
        <v-text-field
          label="手机/用户名/邮箱"
          name="mobile"
          v-model="mobile"
          outline
          block
        ></v-text-field>
      </v-flex>

      <v-flex class="d-flex" xs7>
        <v-text-field
          label="密码"
          type="password"
          name="password"
          v-model="password"
          outline
        >
          <v-chip
            @click="navigateTo({name: 'password-reset-link'})"
            class="my-2"
            small
            slot="append"
          >
            忘记密码
          </v-chip>
        </v-text-field>
      </v-flex>

      <v-flex xs7>
        <v-btn class="red accent-3"
          @click="login" dark block>
          登录
        </v-btn>
      </v-flex>

    </v-layout>
  </v-container>
  <v-snackbar
    v-model="snackbar"
    :timeout="snackbarTimeout"
  >
    {{ snackbarMsg }}
    <v-btn
      color="primary"
      text
      @click="snackbar = false"
    >
      知道了
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
const config = require('../config/config')

export default {
  components: {
  },
  data () {
    return {
      roleCode: 5, // login as tutor
      roles: [],
      mobile: '',
      password: '',
      required: (value) => !!value || '必填',
      snackbar: false,
      snackbarTimeout: 5000,
      snackbarMsg: ''
    }
  },
  async mounted () {
    console.log('\n#Page: login')

    // get account role list
    this.roles = await this.getRoles()
    console.log('\nRetrieved account role list: ', this.roles)
  },
  methods: {
    async getRoles () {
      const res = await AuthenticationService.getRoles()
      console.log(res)
      return res.data.roles
    },
    async login () {
      try {
        const res = await AuthenticationService.login({
          mobile: this.mobile,
          password: this.password,
          roleCode: this.roleCode
        })
        console.log('login button was clicked!', this.mobile, this.password, 'res.data:', res.data)
        this.$store.dispatch('setToken', res.data.token) // this will call @/store/store and trigger the actions in Vuex.store and use setToken in mutation to set the global state token to be the token obtained at this step
        this.$store.dispatch('setAccount', res.data.account)
        this.$store.dispatch('setRoleCodes', res.data.roleCodes)
        this.$store.dispatch('setVersion', config.version)
        this.$store.dispatch('setUser', res.data.userInfo)
        this.$router.push({ name: 'transaction' })
      } catch (error) {
        this.showSnackBar(error.response.data.msg, 2000)
      }
    },
    navigateTo (route) {
      this.$router.push(route) // the $router is attached by default
    },
    showSnackBar (snackbarMsg, timeout) {
      this.snackbar = true
      this.snackbarTimeout = timeout
      this.snackbarMsg = snackbarMsg
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
